/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;1,400&family=Rowdies:wght@300;400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600,800&family=Poppins:ital,wght@0,200;0,400;1,400&family=Rowdies:wght@300;400&display=swap'); */

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  color: #222;
}

body {
  font-family: "Rowdies";
  background-color: #F3F1EE;
}

h1,h2{
  font-family: "Rowdies";
  font-size: 40px;  
}

.container {
  width: 90%;
  margin: 0 auto;
}

.wrapper{
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.navbar a {
  text-decoration: none;
  color: black;
  padding: 5px 15px;
 
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 98px;
  border-bottom: 1px solid black;
  padding: 0px 66px;
  position: relative;
}

.buttonGroup {
  display: flex;
  /* width: 70%; */
  min-height: 98px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.buttonGroup .btn {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}
.hover-doodle{
  display: none;
}
.hover-container{
  position: absolute;
  width: 100%;
    display: flex;
    justify-content: center;
    z-index: -100;
}
.buttonGroup .btn:hover .hover-doodle{
  display: inline;

  
  height: 82px;
  
  align-items: center;
  transform: translateY(-5px);
  
}

.hover-doodle-fixed{
  display: inline;
  /* position: absolute; */
  
  height: 82px;
  
  align-items: center;
  transform: translateY(-5px);
  
}


.buttonGroup a:hover {
  border-radius: 5px;
  outline: 1px solid #222;
  background: var(--elements-color-1, #E53935);
  box-shadow: 2px 2px black;
  padding: 10px 15px;
}
.activeTab a{
  border-radius: 5px;
  outline: 1px solid #222;
  background: var(--elements-color-1, #E53935);
  box-shadow: 2px 2px black;
  padding: 10px 15px;
}

.primary_btn {
  cursor: pointer;
  font-weight: 800;
  display: inline-flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid #222;
  background: var(--elements-color-2, #F9A11F);
  box-shadow: black 3px 3px;
  transition: all 0.3s ease-in-out;
  color: black;
}

.primary_btn:hover {
  transform: translateX(-2px);
  transform: translateY(-2px);
  box-shadow: black 6px 6px;
}

.hero-section {
  /* background: rgb(250,234,95); */
  background-image:linear-gradient(to bottom, rgba(243,241,238, 0.90), rgba(243,241,238, 0.90)), url("./images/gray_line_bg.jpg");
  background-size: 200px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 0.75fr;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  min-height: calc(100vh - 98px);
  padding: 40px 80px;
}

@media(max-width:800px){
  .hero-section{
    grid-template-columns: 1fr 0.5fr;
  }
}

@media(max-width:700px){
  .hero-section{
    grid-template-columns: 1fr;
  }

  .rightSection{
    order: -1;
    margin: 0 !important;
    height: 234px;
  }

  .ideaImage{
    max-height: 200px !important;
  }

  .hero-section{
    padding: 10px 20px;
    min-height: 0 !important;
  }

}

.leftSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rightSection{
  margin: auto;
}


.emp-heading {
  /* color: var(--text-color-2, #d9860c); */
  color: #222;
  font-family: Rowdies;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

.emp-description {
  color: #222;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 5px;
}

.btn-large {
  display: inline-block !important;
  padding: 16px 24px;
  font-size: 14px;
}

.ideaImage {
  /* margin-top: 20px; */
  /* max-width: 500px; */
  max-height: 450px;
}


.education_text {
  color: #222;
  padding: 0px 5px;
}


@media(max-width:1200px) {
  .leftSection {
    width: 100%;
  }

  .section {
    flex-direction: column;
  }

  .ideaImage {
    margin-top: 30px;
    max-width: 100%;
  }

  .container {
    width: 90%;
  }

  .buttonGroup{
    gap: 10px;
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 0.75fr;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 10px;
  border: 3px solid #222;
  background: #F2DAA9;
  padding: 51px 67px;
  gap: 30px;
}

.card-container .main-content{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-us {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* justify-content: space-between; */
}

.image-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

.card-container input[type="text"] {
  font-family: "Montserrat";
  font-weight: 600;
  padding: 20px;
  height: 59px;
  border-radius: 5px;
  border: 3px solid #222;
  background: #FFF;


}

.card-container textarea {
  font-family: "Montserrat";
  font-weight: 600;
  padding: 20px;
  min-height: 120px;
  padding-bottom: 50px;
  border-radius: 5px;
  border: 3px solid #222;
  background: #FFF;
  flex-wrap: wrap;
}



.card-container input[type="submit"] {
  cursor: pointer;
  font-weight: 800;
  display: inline-flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid #222;
  background: var(--elements-color-2, #20b7e9);
  box-shadow: black 3px 3px;
  transition: all 0.3s ease-in-out;
  justify-content: center;


}

.card-container input[type="submit"]:hover {
  transform: translateX(-2px);
  transform: translateY(-2px);
  box-shadow: black 6px 6px;
}

.contactUsImage {
  max-width: 400px;
}

.image-section p {
  display: flex;
  margin: 10px 50px;
  align-items: center;

}

.contactIconImage {
  margin-right: 25px;
}

@media(max-width:1200px) {
  .card-container {
    grid-template-columns: 1fr 0.4fr;
  }

  .image-section {
    align-items: center;
    max-width: 300px;
  }

  .contactUsImage {
    margin-top: 20px;
    max-width: 100%;
  }

}

.oj-container {
  display: flex;
  justify-content: center;
 
}

.our-journy {
  max-width: 75%;
  margin: 0px auto;
  clip-path: polygon(0% 5%, 0% 100%, 100% 100%, 100% 5%);
  transform: translateY(-5%);
}

@media (max-width:1000px){
  .image-section {
    max-width: 250px;
  }
}

@media (max-width:800px){
  
}

@media (max-width:700px){
  .our-journy {
    max-width: 100%;
  }
}

.oppor-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 54px;
  padding-left: 40px;
  padding-right: 40px;
  width: 291px;
  height: 533px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 3px solid #222;
  background: #F3F1EE;
  /* box-shadow: black 3px 3px; */
  transition: all 0.2s;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* .oppor-card:hover {
  box-shadow: black 10px 10px;
  transform: translateX(-6px) translateY(-6px)
} */

.card-description {
  color: #222;
  text-align: center;

  /* Description 12px */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.9px;
  /* 149.167% */

}

.oppor-card-btn {
  display: inline-flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #222;
  background: var(--containers-color-3, #F3F1EE);
  color: #222;
  text-align: center;
  cursor: pointer;
  /* Description 12px */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.9px;
  /* 149.167% */
  box-shadow: black 2px 2px;
  transition: all 0.3s;
  margin-top: 10px;

}

.oppor-card-btn:hover {
  box-shadow: black 4px 4px;
  transform: translateX(-2px) translateY(-2px);
}

.pro-cards{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  justify-content: center;
  margin: 30px;
  margin-top: 20px;
}

@media (max-width:700px){
  .pro-cards{
    grid-template-columns: 1fr;
  }
}

.project-card a{
  display: inline-flex;
  padding: 12px 14px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #222;
  background: var(--containers-color-3, #F3F1EE);
  color: #222;
  text-align: center;
  cursor: pointer;
  /* Description 12px */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  box-shadow: black 2px 2px;
  transition: all 0.3s;
  text-decoration: none;
}


.project-card a:hover {
  box-shadow: black 4px 4px;
  transform: translateX(-2px) translateY(-2px);
}
.oppor-image {
  transform: translateY(-4px);
  height: 200px;
}

.oppor-card-heading {
  color: #222;
  font-family: Rowdies;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 43px;
  text-align: center;
  margin-bottom: 10px;
}

.oppor-cards-container {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  flex-wrap: wrap;
}

.oppor_head {
  color: var(--text-color-2, #333);

  /* Header H2 - 60px */
  font-family: Rowdies;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 60.5px;
  /* 100.833% */
  text-align: center;
  margin-top: 20px;
  /* margin-bottom: 22px; */
}

@media(max-width:500px) {
  .oppor_head {
    font-size: 40px;
  }
}

.oppor_des {
  color: var(--text-color-3, #555);
  text-align: center;

  /* Montserrat 16 px */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.9px;
  /* 143.125% */
}

.op-cont {
  background-image: url("images/curlry-bg.svg");
  background-repeat: repeat-x;
  padding-left: 100px;
  padding-right: 100px;
}

@media(max-width:1100px){
  .op-cont{
    background-image: none;
  }
}

.head-des-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us-heading {
  color: var(--text-color-2, #333);
  font-family: Rowdies;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

@media(max-width:1000px){
  .contact-us-heading{
    font-size: 30px;
  }
  .card-container{
    padding: 15px 25px;
  }
}

@media(max-width:800px){
  .card-container{
    grid-template-columns: 1fr;
  }

  .card-container .image-section{
    flex-direction: column-reverse;
    width: 100%;
  }

  .card-container .image-section .contactUsImage{
    position: relative;
    right: 0;
  }
}

@media(max-width:800px){
  .card-container{
    padding: 10px;
  }
  .wrapper{
    width: 95%;
  }
}

.contact-us-des {
  color: #222;

  /* Montserrat 16 px */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.9px;
  /* 143.125% */
}

/* Volunteer form css */

.vl-form input[type="radio"] {
  display: none;
}

.vl-form .radio-label-yes {
  width: 69px;
  height: 50px;
  flex-shrink: 0;
  border-left: 2px solid #222;
  border-top: 2px solid #222;
  border-bottom: 2px solid #222;
  border-right: 1px solid #222;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 14px 22px;
  background-color: white;
}


.vl-form .radio-label-no {
  width: 69px;
  height: 50px;
  flex-shrink: 0;
  border-right: 2px solid #222;
  border-top: 2px solid #222;
  border-bottom: 2px solid #222;
  border-left: 1px solid #222;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 14px 22px;
  background-color: white;
}

input[type="radio"]:checked+label {
  background-color: #013ea6;
  color: white;
}

.vl-form {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  border-radius: 10px;
  border: 2px solid #222;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 22px;

}

.form-heading-cont {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.form-heading-cont h1 {
  text-align: center;
  color: #222;
  font-family: Rowdies;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.9px;
  /* 95.417% */
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.vl-form .input-container {
  display: flex;
  flex-direction: column;
  margin-top: 33px;
  flex: 1;
  padding: 0px 20px;
}

.vl-form .row-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.vl-form label {
  color: #555;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.9px;
  /* 163.571% */
}

.vl-form input[type="text"] ,textarea{
  border-radius: 5px;
  border: 2px solid #222;
  padding: 14px 20px;
  height: 50px;
  flex-shrink: 0;


}

.vl-form  textarea{
 
  height: 150px;



}




.vl-form input[type="submit"] {
  margin-top: 33px;
  cursor: pointer;
  font-weight: 800;
  display: inline-flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid #222;
  background: var(--elements-color-2, #F9A11F);
  box-shadow: black 3px 3px;
  transition: all 0.3s ease-in-out;
  justify-content: center;


}

.vl-form input[type="submit"]:hover {
  transform: translateX(-2px);
  transform: translateY(-2px);
  box-shadow: black 6px 6px;
}

.radio-label-cont {
  display: flex;
}

.select-drop {

  border: 2px solid #222;
  height: 50px;
  flex-shrink: 0;
  -webkit-appearance: none;
  text-align: center;
  background-image: url("/src/images/dropdown-arrow.svg");
  background-repeat: no-repeat;
  background-position: 30px center;
  border-radius: 5px;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.9px;


}

.phone-drop {
  display: flex;
  justify-content: space-between;
  font-family: Montserrat;

}

.phone-drop input {
  flex: 3;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding-left: 0px !important;
  text-align: center;
}

.phone-drop select {
  flex: 1;
  background-position: 6px center;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: solid black 0px;
  width: 30px;
}

.oppor-bottom-container {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  flex-wrap: wrap;

}


.oppor-bottom-container .left-section {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-right: 10px;
}

.left-section h1 {
  color: #222;
  font-family: Rowdies;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 64.9px;
  /* 108.167% */
}

.left-section p {
  color: #222;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.9px;
  /* 143.125% */
}

.left-section img {
  width: 100%;
  align-self: flex-end;
}

.variation-left-container, .variation-right-container {
  width: 95px;
  height: 316px;
  flex-shrink: 0;
  background-image: url("./images/team/team-bg.png");
  position: absolute;
  transition: all 1s;
  margin-left: 0px;
  clip-path: polygon(0% 2%, 0% 100%, 100% 98%, 100% 0%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-left: 2px solid;
  border-right: 2px solid;
}

/* .variation-left-container::after {
  content: "";
  display: block;
  width: 95px;
  height: 439px;
  background-color: transparent;
  border: 5px solid;

  position: absolute;
  top: 0px;
  transform: skewY(10deg);
  margin:5px;
  
} */




.variation-right-container {
  clip-path: polygon(0% 0%, 0% 98%, 100% 100%, 100% 2%);
}

.team-container img {
  width: 270%;
  transform: translateX(-78px) translateY(40px);
}



.team-container {
  height: 360px;
  position: relative;
  overflow: hidden;
  background-image: url("images/team/team-bg.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  
}

.move-btn {
  padding: 10px;
  border: none;
  background-color: blue;
  color: white;
}

.variation-left-container .test {
  transform: skewY(10);
}

.team-container .team-member-image {
  width: 1000px;
  height: 1000px;
}

.middle-guy {
  max-width: 304px;
    position: absolute;
    top: -47px;
    left: 44%;
  animation: leftTrans 1s;
}

.middle-guy-2 {
  max-width: 304px;
  position: absolute;
  top: -47px;
  left: 44%;
  animation: rightTrans 1s;
}



@keyframes leftTrans {
  0% {
    opacity: 0;
    max-width: 301px;
    top: 0px;
    left: 54%;
  }

  100% {}
}

@keyframes rightTrans {
  0% {
    opacity: 0;
    max-width: 301px;
    top: 0px;
    left: 35%;
  }

  100% {}
}


.team-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  text-align: center;
}

.team-heading h1 {
  color: var(--text-color-2, #333);
    font-family: Rowdies;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
}

.team-heading p {
  color: var(--text-color-3, #555);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  margin: 10px 0;
}

.team-bottom-section {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: top;
  height: 226px;

}

.team-bottom-section .middle-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.team-bottom-section .middle-section h1 {
  color: #222;
  text-align: center;

  /* Header Medium - 40px */
  font-family: Rowdies;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 47.7px;
  /* 119.25% */
}

.team-bottom-section .middle-section p {
  color: #222;
  text-align: center;

  /* Montserrat 14px */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.9px;
  /* 156.429% */
  margin-top: 11px;
}

.team-bottom-section .middle-section img {
  width: 81px;
  height: 69px;
  flex-shrink: 0;
  margin-top: 20px;
}

.team-bottom-section .left-nav {
  cursor: pointer;
  margin-left: 15%;
  margin-right: 5%;
  height: 64px;
  align-self: center;
}

.team-bottom-section .right-nav {
  cursor: pointer;
  margin-right: 15%;
  margin-left: 5%;
  height: 64px;
  align-self: center;
}

#footer {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  position: relative;
  margin-top: 20px;
}

.footer-middle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-middle logo {
  width: 272px;
}

.links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.links-container a {
  text-decoration: none;
  color: #333333;
}

#footer .contact-details {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  flex-direction: column;

}

#footer .contact-container {
  display: flex;
}

#footer .contact-container p,
#footer .contact-container a {
  text-decoration: none;
  margin-left: 17px;
}

#footer .social-media{
  display: flex;
  gap: 20px;
}

.donate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donate-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 203px;
  height: 42px;
  flex-shrink: 0;
  margin-top: 5px;
  font-family: Rowdies;
}

.footer-bottom {
  color: #333;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  font-style: normal;
  font-weight: 600;
  height: 100px;
  text-align: center;
}

.footer-bottom .doc-links{
  display: flex;
  flex-direction: row;
  gap: 15px;
}




.left-cloud1 {
  position: absolute;
  top: 438px;
  left: 109px;
}

.left-cloud2 {
  position: absolute;
  top: 485px;
  left: 63px;
}

.right-cloud1 {
  position: absolute;
  top: 340px;
  right: 109px;
}

.right-cloud2 {
  position: absolute;
  top: 385px;
  right: 63px;
}

.middle-bottom {
  display: flex;
  flex-wrap: wrap;
  padding: 60px;
  border-top: 3px solid black;
  justify-content: center;
  flex-direction: column;
}

.footer-image-top {
  transform: translateY(24%);
  padding-left: 95px;
  max-width: 100%;
}

.reflection {
  position: absolute;
  left: 88px;
  top: 35%;
  z-index: -1;
}

@media (max-width:1020px) {
  .footer-top {
    display: none;
  }

  .footer-middle {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .middle-bottom {
    justify-content: center;
    padding: 20px;
  }

  .donate-container {
    order: 1;
  }

  .contact-details {
    order: 3;
    align-items: center;
    text-align: center;
  }

  .contact-details img {
    display: none;
  }

  .links-container {
    display: none;
  }

  .footer-middle .logo {
    order: 2;
    width: 200px;
  }

  .emp-heading {
    font-size: 30px;
    line-height: 1.2;
  }

  .emp-description {
    padding-top: 5px;
    font-size: 14px;
  }

}


.activity {
  position: relative;
  background-image: url(../src/images/activity-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 1189 / 476;
  min-height: 342px;
  max-width: 95%;
  margin: auto;
  padding: 65px 40px 50px 150px;
}

@media (min-width:1300px) and (max-width:1400px){
  .activity{
    padding: 56px 40px 50px 135px;
  }
}

@media (min-width:1220px) and (max-width:1300px){
  .activity{
    padding: 56px 40px 50px 135px;
    background-image: url(../src/images/activity-bg-1.svg);
  }

  .activity #activity-image-text{
    grid-template-columns: 1fr 0.45fr !important;
  }
}

@media (min-width:1000px) and (max-width:1220px){
  .activity{
    padding: 56px 56px 50px 115px;
    background-image: url(../src/images/activity-bg-2.svg);
    max-width: 100%;
  }

  .activity #activity-image-text{
    grid-template-columns: 1fr !important;
    gap: 5px;
  }

  .activity #activity-image-text .right-image{
    display: none;
  }

  .activity p{
    font-size: 14px;
  }

  .current-activities .right-nav {
    right: -1% !important;
  }
  
  .current-activities .left-nav {
    left: -1% !important;
  
  }
}

@media (max-width: 1000px){
  .activity{
    padding: 80px 56px 50px 105px;
    background-image: url(../src/images/activity-bg-3.svg);
    aspect-ratio: 1189 / 707;
    max-width: 98%;
  }

  .activity #activity-image-text{
    grid-template-columns: 1fr !important;
    gap: 5px;
  }

  .activity #activity-image-text .right-image{
    display: none;
  }

  .activity p{
    font-size: 14px;
  }

  .current-activities .right-nav {
    right: -1% !important;
  }
  
  .current-activities .left-nav {
    left: -1% !important;
  
  }
}

@media (max-width: 800px){
  .activity{
    padding: 50px 56px 50px 80px;
    max-width: 98%;
  }

  .activity .text-btn-container {
    padding-right: 8px !important;
    gap: 5px !important;
  }

  .activity h2{
    font-size: 20px !important;
  }

  .activity p{
    font-size: 14px !important;
  }
}

@media (max-width: 675px){
  .activity{
    padding: 60px 56px 50px 70px;
    background-image: url(../src/images/activity-bg-4.svg);
    aspect-ratio: 1189 / 1031;
    max-width: 98%;
  }

  .current-activities{
    width: 90% !important;
  }

  .activity h2{
    font-size: 20px !important;
  }
}

@media (max-width: 525px){
  .activity{
    padding: 45px 40px 40px 50px;
    background-image: url(../src/images/activity-bg-5.svg);
    aspect-ratio: 1189 / 1346;
  }

  .activity #activity-image-text{
    gap: 5px;
  }

  .activity p{
    font-size: 12px !important;
  }

  .oppor-card-btn{
    font-size: 10px;
    width: auto !important;
    padding: 4px 8px;
  }

  .current-activities .right-nav{
    right: -8px !important;
  }

  .current-activities .left-nav{
    left: -8px !important;
  }

  .activity .text-btn-container {
    padding-right: 8px !important;
    gap: 5px !important;
  }

}

@media (max-width: 375px){
  .activity{
    padding: 40px 20px 40px 50px;
  }
}


.activity h2 {
  color: #FFC;
  font-family: Rowdies;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.activity p {
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.activity #activity-image-text{
  display: grid;
  grid-template-columns: 1fr 0.65fr;
  gap: 15px;
  /* min-height: calc(100% + var(--activity-padding-top)); */
}

.activity .text-btn-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  padding-right: 30px;
}

.activity .know-more {
  width: 117px;
  justify-content: center;
}

.right-image{
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-image img{
  min-width: 75%;
}

@media(max-width:850px) {
  .circle{
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border: 1px solid black;
    margin: 5px;
  }
  
}

.current-activities {
  position: relative;
  max-width: 1200px;
}

.current-activities .right-nav {
  width: 52px;
  position: absolute;
  top: 31%;
  right: 1%;
  cursor: pointer;
}

.current-activities .left-nav {
  width: 52px;
  position: absolute;
  top: 31%;
  left: -1%;
  cursor: pointer;

}

@media (max-width:1000px) {
  .current-activities .right-nav{
    width: 30px;
    
    top: 43%;
    right: -4%;
  
  }

  .current-activities .left-nav{
    width: 30px;
    
    top: 43%;
    left: -5%;
  
  }

  .app-container{
    margin-top: 70px !important;
  }

  .hero-section{
    min-height: 0;
  }
  
}
.nav-logo{
   cursor: pointer;
}

.navbar .ham{
  width: 25px;
  display: none;
}

.nav-logo-horizontal{
  height: 70px;
  display: none;
  padding: 5px;
}

@media(max-width:1000px){
  .navbar{
    padding: 0 30px;
  }
  .navbar .buttonGroup{
    display: none;
  }
  .navbar .primary_btn{
    display: none;
  }
  .navbar{
    padding: 0px 24px;
    min-height: 45px;
    
  }
  .navbar .ham{
    display: inline;
    height: 45px;
 
  }
  .navbar .nav-logo{
    width: 31px;
    display: none;
  }
  .nav-logo-horizontal{
    display: inline;
  }
}

.side-menu-container{
  width: 100%;
  height: 110vh;
  background-color: #212121;
  position: fixed;
  z-index: 300;
  top: 0px;
  right: 0;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.side-menu-container a{
  color: white;
  color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 12.9px; /* 80.625% */
}

.side-menu-container .btn{
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 26px 0px;
}
.idea-black{
  width: 90%;
}

.side-button-group{
  margin-top: 72px;
}

.side-menu-container .close-btn{
  position: absolute;
  right: 24px;
  top:24px;
}


.project-card{
  /* min-height: 433px; */
  border-radius: 10px;
  border: 3px solid #222;
  background: #EDC346;
  padding: 25px;
  position: relative;
  max-width: 400px;
}

.project-card .main-content{
  display: flex;
  flex-direction: column;
}

.project-card h3{
  color: #222;
  font-size: 22px !important;
  line-height: 1.7;

/* Header Medium - 40px */
font-family: Rowdies;
font-size: 40px;
font-style: normal;
font-weight: 400;
  
}

.project-card p{
  color: var(--text-color-2, #333);
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 18.9px; /* 135% */
flex-shrink: 0;
margin-bottom: 17px;
}

.project-card .image-bottom-right{
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
} 

.oppor-card-btn a{
  text-decoration: none;
  color: black;
}

.pro-view-more{
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.box-shadow{
  box-shadow: #222 3px 3px;
}

.shadow-animation {
  box-shadow: black 3px 3px;
  transition: all 0.2s;
}

.shadow-animation:hover {
  box-shadow: black 10px 10px;
  transform: translateX(-6px) translateY(-6px)
}

.load-more{
  background-color: #F9A11F;
  width: 107px;
}

.projects-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("images/projects/background.svg");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y:22% ;
  background-size: 100%;
}
.projects-container .primary_btn{
  margin-top: 20px;
}
@media(max-width:1300px){
  
.projects-container{
  background-image: none;
}
}

/* @media(max-width:700px){
  .project-card {
    width: 100%;
    height: 500px;
  }
} */
.activities-area{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.activities-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 30px;
  max-width: 1200px;
}

.activity-card{
  display: grid;
  grid-template-columns: 1fr 0.65fr;
  gap: 10px;
  padding: 30px;
  border-radius: 10px;
  border: 3px solid #222;
  height: 100%;
  position: relative;
}

.activity-card .text-p{
  display: flex;
  flex-direction: column;
  gap: 10px;

}

.activity-card .image-bottom-right{
  max-width: 100%;
  margin: auto 0;
}

.activity-card .image-bottom-right img{
  max-width: 100%;
}

.activity-card h3{
  color: #222;
  font-size: 22px !important;
  line-height: 1.5;
  font-family: Rowdies;
  font-style: normal;
  font-weight: 400;
}

.activity-card p{
  color: var(--text-color-2, #333);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}
.activity-card .know-more{
  color: var(--text-color-2, #333);
  font-family: Rowdies;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20.9px;
  cursor: pointer;
}



.project-details-container{
  display: flex;
  flex-direction: column;
align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}


.project-details-container .top{
  display: flex;
  justify-content: space-around;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
}

.project-details-container .main-image{
  width: 600px;
  height: 600px;
  border: 4px solid #222;
  background: #D9D9D9;
  
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 5px 5px black;
}

.project-details-container .right{
  width: 564px;
  display: flex;
  flex-direction: column;
  
  
}

.project-details-container .right .number-detail-box{
  align-self: center;
}

.project-details-container .main-title{
  color: #222;
font-family: Rowdies;
font-size: 60px;
font-style: normal;
font-weight: 400;
line-height: 20.9px; /* 34.833% */
margin-top: 190px;
margin-bottom: 38px;
}

.project-details-container .top-dec{
  color: #222;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 31.9px; /* 199.375% */
margin-bottom: 21px;
}


.number-detail-box{
  display: flex;
  border-radius: 5px;
border: 3px solid #222;
background: #971543;
justify-content: center;
padding: 23px;
width: 90%;
height: 153px;
}

.number-detail-box p{
  color: #FFF;
text-align: center;
font-family: Montserrat;
font-size: 10px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.number-detail-box img{
  height: 52px;
  margin-bottom: 5px;
}

.number-detail-box .img-details{
  margin: 0px 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.number-detail-box h1{
  color: #FFF;
text-align: center;
font-family: Rowdies;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.project-details-container .doodle{
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
}

.project-details-container .middle{
  border-radius: 5px;
border-top: 3px solid #222;
border-bottom: 3px solid #222;
background: #971543;
color: #FFF;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 36px; /* 225% */
padding: 49px 103px;
margin: 75px 0px;
width: 100%;
}

.project-details-container .volunteer-container{
  display: flex;
  border-radius: 10px;
border: 4px solid #222;
background: #971543;
width: 80%;
justify-content: space-around;
padding: 38px 62px;
}

.project-details-container .volunteer-container h3{
  color: #FFF;
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 1.4;
}


.project-details-container .volunteer-container p{
  color: #FFF;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.project-details-container .volunteer-container .vol{
  height: 43px;
}

.project-details-container .bottom-image-container{
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
  
}
.project-details-container .bottom-image{
  width: 317px;
  height: 317px;
  background-position: center;
  border-radius: 6px;
border: 3px solid #222;
background-size: cover;
margin: 61px;
}



@media(max-width:1000px){
  .activity-card{
    grid-template-columns: 1fr;
    justify-content: space-between;
  }

  .activity-card .text-p {
    width: 100%;
  }
  .activity-card .image-bottom-right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
  }
}

@media (max-width:700px){
  .activities-container{
    grid-template-columns: 1fr;
  }
}

@media (max-width:500px){
  .activities-container{
    margin: 20px 20px;
  }
}

.popup{
  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgb(0, 0, 0,0.5);
  height: 100vh;
  z-index: 1280;
  
}
.popup .project-card{
  height: 80vh;
}
@media(max-width:1000px){
  .popup .project-card{
    height: 60vh;
    margin-top: auto;
    margin-bottom: auto;
  }
}
.close-popup{
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: transparent;
  font-size: 20px;
  z-index: 300;
  font-weight: bold;
  top: 0;
  right: 0;
  margin: 10px;
  border: none;

}
.close-popup:hover{
  color: red;
}

.popup p{
  height: 100%;
}

.fade-in-fade-out{
  animation: fadeout 1s;
}

@keyframes fadeout {
  
50%{
    opacity: 0;
  }
100%{
  opacity: 1;
}
  
}


.moving-circles-container{
  display: flex;
  justify-content: center;
}
.circle{
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid black;
  margin: 5px;
}

@media(max-width:850px) {
  .circle{
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border: 1px solid black;
    margin: 5px;
  }
}

.go-back{
  width: 135px;
  justify-content: center;
  align-items: center;
}

.stats{
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  margin: 70px auto;
}

.stats .stat-bg{
  position: absolute;
  width: 100%;
  z-index: 1;
}

.stats .stat-bg-1{
  display: none;
}

.stats .stat-circle{
  width: 150px;
  height: 150px;
  background-color: #F2AF29;
  border: 2px solid;
  border-radius: 100px;
  position: relative;
  z-index: 2;

}
.stat-circles-container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 80px;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  z-index: 2;
  transform: scale(0.9);
}

@media (max-width:1200px){
  .stat-circles-container{
    transform: scale(0.8);
    gap: 100px;
  }
}


@media (max-width:1000px){
  .stat-circles-container{
    transform: scale(0.7);
    gap: 100px;
  }
  .stats{
    margin: 50px auto;
  }
}

@media (max-width:850px){
  .stat-circles-container{
    transform: scale(0.5);
    gap: 100px;
  }
  .stats{
    margin: 30px auto;
  }
}

@media (max-width:675px){
  .stat-circles-container{
    padding: 15px;
    grid-template-columns: repeat(2, 1fr);
    transform: scale(0.6);
    grid-row-gap: 50px;
    grid-column-gap: 120px;
  }
  .stats .stat-bg{
    display: none;
  }
  .stats .stat-bg-1{
    display: block;
    position: absolute;
    height: 100%;
    z-index: 1;
    aspect-ratio: 559 / 676;
  }
  .stats{
    margin: -20px auto;
  }
}

@media (max-width:675px){
  .stat-circles-container{
    transform: scale(0.5);
  }
  .stats{
    margin: -20px auto;
  }
}

@media (max-width:375px){
  .stat-circles-container{
    transform: scale(0.5);
  }
  .stats{
    margin: -20px auto;
    padding: 0 20px;
  }
}


.stv-1,.stv-2,.stv-3,.stv-4{
  position: absolute;
}
.stv-1{
  bottom: -6px;
    left: -42px;
}
.stv-2{
  bottom: -6px;
    left: -6px;
}

.stv-3{
  bottom: -6px;
    right: -42px;
}
.stv-4{
  bottom: -6px;
    right: -6px;
}


.numbers{
  transition: all 6s;
 
}
.number{
  font-size: 30px;
  text-align: center;
}

.circle-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.circle-text .text{
  color: white;
  margin-top: 10px;
}

.bulb-container{
  display: flex;
  justify-content: center;
  /* margin-top: 40px; */
}
.bulb-container .bulb{
  width: 41%;
}
.bulb-container h1{
  color: var(--text-color-2, #333);

/* Header H2 - 60px */
font-family: Rowdies;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: 60.5px; /* 100.833% */
}

@media(max-width:1000px){
  .bulb-container h1 {
    font-size: 39px;
    line-height: 1em;
    margin-bottom: 10px;
  }
}
.vf-container{
  width: 50%;
  position: relative;
  
}
.vf{
  position: relative;
  display: flex;
  gap: 50px;
  color: var(--text-color-3, #555);

/* Montserrat 14px */
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 21.9px; /* 156.429% */
}

.v-text, .f-text{
  position: relative;
  /* margin-right:50px !important; */
}
/* .v-text{
  text-align: right;
} */
.arrow-1, .arrow-2{
  position: absolute;
  top: 0%;
  left: -50px;
}
.vf .multi-line{
  display: none;
}
@media(max-width:1000px){
  .vf{
    font-size: 12px;
  }
  .arrow-1, .arrow-2{
    left: -30px;
  }
}
@media(max-width:500px){
  .vf{
    flex-direction: column;
    margin-left: 10px;
    gap: 30px;
  }
  .vf-container .single-line{
    display: none;
  }
  .vf .multi-line{
    display: block;
    /* margin-bottom: 20px; */
  }
  .vf .v-text, .vf .f-text{
    margin-left: 20px;
  }
  .loop-img-container{
    width: 96% !important;
  }
}
.loop-bg{
  width: 100%;
}
.loop-img-container{
  position: relative;
}

.square-p, .square-p a{
  position: absolute;
  width: 168px;
  height: 168px;
  background-color: black;
  opacity: 0;
}
@media(max-width:850px){
  .square-p, .square-p a{
   
    width: 100px;
    height: 100px;
    
    opacity: 0;
  }
}


@media(max-width:700px){
  .square-p, .square-p a{
   
    width: 50px;
    height: 50px;
    
    opacity: 0;
  }
}

.p-ganitham{
  top: -3%;
    left: 46%;
}

.p-disha{
  top: 76%;
  left: 67%;
}

.p-spark{
  top: 31%;
  left: 85%;
}

.p-lets-learn{
  top: 76%;
  left: 26%;
}

.testimonial-card{
  /* width: 400px; */
  color: #f1f1f1 !important;
  flex-shrink: 0;
  border-radius: 10px;
  border: 4px solid #222;
  background: var(--containers-color-2, #971543);
  padding: 20px;
  display: grid;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  /* margin: 22px; */
}

.testimonial-card .main-text{
  color: #f1f1f1;

/* Description - 18px */
font-family: Rowdies;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 24.4px; /* 135.556% */
}

.testimonial-card .name{
  color: #f1f1f1;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20.9px; /* 149.286% */
}

.testimonial-card .pos{
  color: var(--containers-color-3, #F3F1EE);
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 1; /* 109% */

}

.testimonials-container{
  display: grid;
  grid-template-columns:  1fr 1fr 1fr;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px;
  margin: 25px 50px;
  /* margin-bottom: 25px; */
}

.testi-bottom{
  margin-top:40px;
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  gap: 10px;
}

.testi-bottom .testi-image{
  width: 75px !important;
  height: 75px !important;
  overflow: hidden;
}

.testi-bottom .testi-image img{
  object-fit: cover;
  width: 74px !important;
  height: 74px !important;
  border-radius: 100%;
  border: 2px solid #4c0314;
}

.test-load-cont{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.test-load-cont h1{
  color: var(--text-color-2, #333);
  font-family: Rowdies;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 60.5px; /* 100.833% */
  /* margin-bottom: 20px; */
  padding: 20px;
  }

.image-card{
  border-radius: 10px;
border: 3px solid #222;
background: #D9D9D9;
width: 277px;
height: 277px;
flex-shrink: 0;
background-size: cover;
background-position: center;
}

.gallery-container{
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
}
.gallery-container .image-card{
  margin: 25px;
}

.btn-project{
  position: relative;

}

.hd{
  position: absolute;
  width: 30%;
}

.h-d1{
  top: 80%;
    left: 46%;
}

.project-dropdown{
  background-color: #1e1e1e;
  border-radius: 0 0 5px 5px;
  color: white;
  width: 400px;
  position: absolute;
  top: 70px;
    left:0px;
    z-index: 240;

}
.drop-item{
  display: flex;
  padding: 22px;
  justify-content: flex-start;
  cursor: pointer;
  
}

.drop-item .main{
  color: #FFF;
font-family: Rowdies;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.drop-item .drop-text-sub{
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.drop-item .sub-text{
  color: #FFF;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
opacity: 0.4;
margin-top: 4px;

}

.drop-item:hover{
  background-color: white;
}
.drop-item:hover .main{
  color: #333;

}

.cirlcle-image{
  position: relative;
  background-color: white;
  border-radius: 100px;
  width: 56px;
  height: 56px;

}
.drop-image{
  width: 80%;
  height: 75%;
  position: absolute;
  top: 8%;
  left: 11%;
 
}

.drop-item:hover .cirlcle-image{
  background-color: #EDC346;
}
.drop-item:hover .sub-text{
  color:black;
font-family: Montserrat;
}

.error{
  color: red;
  text-align: center;
}

.error *{
  color: red;
}

@media(max-width:1000px){

  .form-heading-cont{
    width: 90%;
  }
  .left-section{
    width: 90%;
  }
  .row-flex{
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .left-section h1{
    font-size: 35px;
  }
  .left-section p{
    font-size: 14px;
  }
}




.our-associations{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.our-associations .logos{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding: 40px;
  flex-wrap: wrap;
}
.logos img{
  width: 20%;
  height: 60px;
}

.projects-container h1, .activities-area h2{
  color: var(--text-color-2, #333);
  font-family: Rowdies;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 60.5px; /* 100.833% */
}

.linkdin{
  cursor: pointer;
}

@media (max-width:1000px){
  .team-bottom-section{
    height: 550px;
  }
  .team-bottom-section .left-nav, .team-bottom-section .right-nav{
    margin-left: 5%;
    margin-right: 5%;
    align-self: flex-start !important;
    margin-top: 13px;
  }
  .team-bottom-section h1{
    font-size: 30px !important;
  }
  .team-bottom-section p{
    font-size: 10px;
  }

  .team-bottom-section img{
    width: 31px;
    margin: 0px;
  }
  .middle-section{
    width: 100% !important;
  }
  .team-container {
    height: 470px;
  }
  .team-heading h1 {
    font-size: 40px;
    line-height: 1.1em;
  }
  #middle-member {
    display: flex;
    justify-content: center;
   
    max-width: 336px;
    transform: translateY(200px) translateX(-135px);

  }
  .variation-left-container, .variation-right-container {
    width: 57px;
    height: 396px;
  }
  .variation-left-container img, .variation-right-container img{
    width: 400%;
    transform:translateY(173px) translateX(-80px)
  }
  
}

.text-container{
  position: absolute;
  z-index: 200;
}

.stat-number{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2em;
  color: #222;

/* Header Medium - 40px */
font-family: Rowdies;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: 47.7px; /* 119.25% */
transform: translateY(58px);
clip-path:polygon(0px 0px, 0px 40px, 100% 40px, 100% 0px)
}

@media(max-width:1100px){
  .navbar .buttonGroup{
    gap: 6px;
  }

  .bulb{
    display: none;
    
  }
  .vf-container{
    width: 100%;
  }
}

@media(max-width:1120px){
  .navbar{
    padding: 0 30px;
  }
}

@media (max-width:1280px){
  .testimonials-container{
    grid-template-columns: 1fr 1fr;
  }
}

@media(max-width:1000px){
  /* .testimonial-card{
    width: 319px;
  } */
  .test-load-cont h1{
    font-size: 40px;
    /* margin-bottom: 10px; */
  }

  .nav-offset{
    margin-top: 90px !important;
  }
}

@media(max-width:1000px){
  .project-details-container{
    padding: 20px;
  }
  .project-details-container .main-image {
    width: 300px;
    height: 300px;
   
  }
  .project-details-container .doodle{
    display: none;
  }
  .project-details-container .main-title {
    font-size: 40px;
    margin-top: 60px;
    text-align: center;
    line-height: 1.2em;
  }
  .project-details-container .top{
    flex-direction: column;
    align-items: center;
  }
  .project-details-container .right{
    width: 100%;
  }
  .number-detail-box h1{
    font-size: 22px;
  }
  .project-details-container .middle{
    padding: 23px;
  }
  .volunteer-container{
    flex-direction: column;
    align-items: center;
    padding: 14px 15px !important;
  }
  .volunteer-container h1{
    font-size: 25px;
    margin-bottom: 10px;
  }
  .volunteer-container p{
    font-size: 15px;
    margin-bottom: 10px;
  }

  .projects-container h1, .activities-area h2{
    font-size: 30px;
    line-height: 35.5px;
  }
  .project-card{
    padding: 20px;
  }
  .activity-card .image-bottom-right{
    padding: 2px 43px;
  }
  .arrow-1,.arrow-2{
    width: 24px;
  }
  .testimonials-container{
    align-items: center;
  }
  .project-details-container .bottom-image{
    width: 260px;
    height: 260px;
    margin: 40px;
  }
  .op-cont{
    padding-left: 20px;
    padding-right: 20px;
  }
  .our-associations .logos{
    padding: 15px;
  }
  .contactInfo p {
    font-size: 12px;
    display: flex;
  
}
  .contactInfo img{
    width: 26px;
  }
  .oppor-card{
    padding-left: 20px;
    padding-right: 20px;
   
  }
  .vl-form{
    padding: 0px;
  }

  .vl-form input[type="text"]{
    height: 45px;
  }
  .vl-form input[type="submit"]{
    margin: 20px 20px;
  }
  .oppor_head{
    font-size: 30px;
  }
  .volunteer-container h1{
    font-size: 21px !important;
    text-align: center;
  }
  .volunteer-container p{
    font-size: 15px !important;
    text-align: center;
  }
  .team-bottom-section .middle-section{
    align-items: start;
  }
  .team-bottom-section .middle-section h1{
    font-size: 25px !important;
    line-height: 1.2em;
    margin-top: 10px;
    align-self: center;
  }
  .team-bottom-section .middle-section p{
    text-align: left;
  }

}

.logos img:nth-child(4){
  opacity: 0.4;
}

.oppor-card .oppor-card-btn{
  z-index: 10;
}
.app-container{
  margin-top: 98px;
}

@media(max-width:500px){
  .app-container{
    overflow: hidden;
  }
  .team-container{
    width: 200%;
    transform: translateX(-190px);
  }

  #middle-member{
    transform: translateY(170px) translate(-122px);
  }
  .project-details-container .top-dec,.project-details-container .middle{
    line-height: 1.5em;
  }
}

.view-all{
  color: #FFF;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding: 30px;
cursor: pointer;
}

/* .general-content{
  padding: 66px;
} */

.general-content h1{

  color: #222;
font-family: Rowdies;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: 46.4px; /* 77.333% */
margin-bottom: 50px;
}

.general-content p{
  color: #222;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 26.9px; /* 168.125% */
}
.sub-content{
  margin-left: 20px;
}
.sub-item-2{
  margin-left: 30px;
  display: flex;
}
.index-text{
  margin-right: 5px;
}

/* @media (max-width:1120px){
  .buttonGroup{
    width: 80%;
  }
} */

@media(max-width:1000px){
  .general-content h1{
    font-size: 33px;
  }
}
.general-content .top{
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.general-content .top, .general-content .bottom{
  color: #222;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.9px;
}
.general-content .left{
  display: flex;
  align-items: center;
}
.general-content .right{
  aspect-ratio: 16/11;
  width: 100%;
}

.center-text{
  text-align: center;
}

@media(max-width:1250px){
  .general-content .top{
    grid-template-columns: 1fr 0.75fr;
  }
  .general-content .left{
    width: 100%;
  }
  .general-content .right{
    display: flex;
    justify-content: flex-start;
    align-items: top;
    width: 100%;
  }
  .general-content .top{
    flex-direction: column;
  }
}

@media(max-width:800px){
  .general-content .top{
    grid-template-columns: 1fr;
  }
  .testimonial-card .main-text{
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.8; /* 180% */
  }

  .testimonial-card .name{
    font-size: 12px;
  }
  .testimonial-card .pos{
    font-size: 12px;
  }
}

.nav-fixed{
  position: fixed;
  background-color: #F3F1EE;
  z-index: 1000;
  width: 100%;
  top: 0;
}

.gcert{
  font-weight: bold;
  color: black;
  font-family: 'Rowdies';
}
.event-box{
  position: absolute;
  top: -7px;
  color: #971543;
  font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  background-color: white;
  border-radius: 3px;
  padding: 3px;
  /* margin-left: 10px; */
}
.event-container{
  position: relative;
  display: flex;
  gap: 5px;
  flex-wrap: wrap-reverse;
  border-top: 1px solid #d0527f;
  margin-top: 15px;
  padding-top: 10px;
  /* max-width: 80%; */
}

.event-container .event-name{
  color: var(--Containers-color-3, #F3F1EE);
font-family: Montserrat;
font-size: 12px;
font-style: italic;
font-weight: 600;
line-height: 1.4; /* 109% */
/* margin-right: 7.5px; */
}


@media(max-width:800px){
  .event-container .event-name{
    font-size: 12px;
  }

}
.image-card{
  position: relative;
}

.card-text{
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0px;
  /* height:108px; */
  
  color: #FFF;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 1.4; 
padding-top: 48px;
padding-bottom: 18px;
padding-left: 29px;
padding-right: 29px;
background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1.6));
}

@media (max-width:850px){
  .testimonials-container{
    grid-template-columns: 1fr;
    max-width: 500px;
  }
}

@media (max-width:500px){
  .testimonials-container{
    margin: 20px 15px !important;
  }
}

@media (max-width:330px){
  .testimonial-card{
    width: 300px;
  }
}

.home-container{
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.nav-offset{
  margin-top: 120px;
}

.cta-container{
  margin-bottom: 30px;
}

#map{
  aspect-ratio: 5 / 2;
  width: 100%;
  /* margin-top: -50px; */
  /* margin-bottom: 50px; */
}

.leaflet-container{
  aspect-ratio: 5 / 2;
  width: 100%;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-top{
  z-index: 999 !important;
}

.leaflet-tooltip {
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px !important;
  padding: 10px !important;
  padding-right: 15px !important;
}

.leaflet-tile-container{
  filter: saturate(0.45) brightness(0.9);
}

.leaflet-marker-pane img{
  transition: all 200ms ease-in-out !important;
  width: 10px;
  height: 10px;
}

.leaflet-marker-pane img:hover{
  width: 25px !important;
  height: 25px !important;
}

@media (max-width:900px){
  #map{
    aspect-ratio: 4 / 6;
  }
  
  .leaflet-container{
    aspect-ratio: 4 / 6;
  }
}

.error-404{
  display: flex;
  flex-direction: column;
  gap: 10px;
  place-items: center;
  justify-content: center;
  min-height: 50vh !important;
}

.error-404 button{
  margin-top: 15px;
}



.association-logos,
.volunteer-partners {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.logos-container {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.logo-item {
  text-align: center;
  width: 200px;
}

.logo-item img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.logo-item p {
  margin: 0.5rem 0;
  font-size: 0.9rem;
}